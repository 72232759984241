import React from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import SVG from "react-inlinesvg";
import { checkLimits } from "../../plans";
import ImportEvents from "../../pages/products/import/Import";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import actions from "../../../redux/actions";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function NewProductDropdown({ className = "", toggleClassName = "", children, user, drop = "down", alignRight = false, fixed = false }) {
    let history = useHistory();

    async function openYoutubeImportOnClick() {
        if(await checkLimits.canAddProduct(user))
            ImportEvents.dispatchShowYoutubeImport();
    }

    async function openRssImportOnClick() {
        if(await checkLimits.canAddProduct(user))
            ImportEvents.dispatchShowRssImport();
    }

    async function fromScratchOnClick() {
        if(await checkLimits.canAddProduct(user))
            history.push("/create-product");
    }

    async function shareCodeOnClick() {
        if(await checkLimits.canAddProduct(user))
            ImportEvents.dispatchShareCodeImport();
    }

    return (
        <Dropdown className={(fixed ? "fixed-dropdown " : "") + className} drop={drop} alignRight={alignRight}>
            <Dropdown.Toggle className={toggleClassName}>
                {children}
            </Dropdown.Toggle>
            <Dropdown.Menu popperConfig={fixed ? { strategy: "fixed" } : {}} renderOnMount>
                <Dropdown.Item onClick={fromScratchOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/create.svg")} />
                    </div>
                    Start from scratch
                </Dropdown.Item>
                <Dropdown.Item onClick={openYoutubeImportOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/youtube.svg")} />
                    </div>
                    Import from YouTube
                </Dropdown.Item>
                <Dropdown.Item onClick={openRssImportOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/rss.svg")} />
                    </div>
                    Import from RSS feed
                </Dropdown.Item>
                <Dropdown.Item onClick={shareCodeOnClick} className="no-plan-cant-click">
                    <div className="icon">
                        <SVG src={toAbsoluteUrl("/media/def-image/icons/share.svg")} />
                    </div>
                    Import share code
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default injectIntl(
    connect(
        (state) => ({
            user: state.auth.user,
        }),
        actions.product
    )(withRouter(NewProductDropdown))
);