import React, { useEffect, useState, useRef, useCallback } from "react";
import api from "../../../../redux/api";
import _ from "lodash";
import { injectIntl } from "react-intl";
import PublicPageLayout from "../../generic/PublicPageLayout";
import { ProductLogo } from "../../../../_metronic/layout/components/productLogo";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, nl2br, isValidUrl } from "../../../../_metronic/_helpers";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/pt";
import "moment/locale/sv";
import "moment/locale/ru";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Modal, ModalBody } from "react-bootstrap";
import { Form, Formik } from "formik";
import { formatLength, Stars, getInputClassName, displayFeedback, displayPrice, getProductUrl, AuthorSocialIcons, getAuthorUrl } from "../../../helpers";
import { PlayerEvents } from "../../generic/Player";
import { NavLink } from "react-router-dom";
import schemas from "../../../schemas";
import { isMobile } from "react-device-detect";
import { toastMessage } from "../../../helpers";
import { CardElement, Elements, useStripe, useElements, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ReCaptcha } from "react-recaptcha-v3";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { momentLocales } from "../../../../locales";

require("dotenv").config();

/**
 * Component PurchaseSummary.
 * @param {Object} [props.product]
 * @param {Object} [props.author]
 * @param {Object} [props.className]
 * @returns {Object}
 */
function PurchaseSummary({ product, author, className }) {
  let authorUrl = getAuthorUrl(author),
    { t } = useTranslation(),
    price = displayPrice(product, { long: true, jsx: true, t });

  return <div className={"purchase-summary " + (className || "")}>
    <div className="d-flex align-items-center">
      <ProductLogo product={product} />
      <div className="flex-grow-1">
        <h2>{product.name}</h2>
        <p>{t("Created by:")} <a href={authorUrl} className="author-link">{author.firstName ? author.firstName + (author.lastName ? " " + author.lastName : "") : author.email}</a></p>
      </div>
      {price}
    </div>
  </div>;
}

/**
 * Component EpisodeList
 * @param {string} [props.className]
 * @param {boolean} [props.desktop]
 * @param {boolean} [props.mobile]
 * @param {Object} [props.product]
 * @returns {Object}
 */
function EpisodeList({ product, className, desktop, mobile }) {
  let [displayed, setDisplayed] = useState(4),
    [shadowOpacity, setShadowOpacity] = useState(0),
    [playingSample, setPlayingSample] = useState(null),
    playerCallback = (state, data) => {
      if(state == "playing")
        setPlayingSample(data.episode._id);
      else
        setPlayingSample(null);
    },
    lastUpdate = null,
    { t, i18n } = useTranslation();

  for(let i = 0; i < product.episodes.length; i++) {
    let date = moment(product.episodes[i].updatedAt);
    if(!lastUpdate || lastUpdate.valueOf() < date.valueOf()) lastUpdate = date;
  }

  let list = [],
    to = desktop ? product.episodes.length : Math.min(product.episodes.length, displayed);
  for(let i = 0; i < to; i++)
    list.push(<div key={i} className="d-flex episode-item align-items-center">
      <div className={"icon " + (product.episodes[i].isSample ? "play" : "lock")} onClick={() => {
        if(!product.episodes[i].isSample || !product.episodes[i].publicAudio.audioUrl) return;
        if(playingSample == product.episodes[i]._id)
          return PlayerEvents.dispatchPause();
        PlayerEvents.dispatchPlayEpisode(product, product.episodes[i], {
          stopAt: 180,
          callback: playerCallback
        });
      }}>
        <SVG src={toAbsoluteUrl(product.episodes[i].isSample
          ? (playingSample == product.episodes[i]._id ? "/media/svg/icons/Media/Pause.svg" : "/media/svg/icons/Media/Play.svg")
          : "/media/def-image/icons/create/lock.svg")} className="svg-icon" />
      </div>
      <div className="title flex-grow-1">{product.episodes[i].title}</div>
      <div className="length">{formatLength(product.episodes[i].publicAudio.durationInSeconds)}</div>
    </div>);

  return <div className={"episode-list " + (desktop && "desktop ") + (mobile && "mobile ") + (className || "")}>
    <div className="summary d-flex align-items-center">
      <div className="flex-grow-1 title">
        <h1>{t("Episodes")}</h1>
        <p className="text-gray-600">{product.episodes.length} {t("episodes")} ({formatLength(product.totalDuration)})</p>
      </div>
      {lastUpdate && <div className="last-update text-right">
        <span className="text-muted">{t("Last update:")}</span><br /> {moment(lastUpdate).locale(momentLocales[i18n.language || "en"]).format("D MMM Y")}
      </div>}
    </div>
    <div className="list scrollbar">
      <div style={{ opacity: shadowOpacity }} className="scrollbar-shadow" />
      {list.length ? <>
        <Scrollbars
          autoHeight
          hideTracksWhenNotNeeded
          autoHeightMax={585}
          className="scrollbar-view"
          renderTrackVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-track" />}
          renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style }} className="scrollbar-thumb" />}
          onUpdate={v => {
            let { scrollTop } = v;
            setShadowOpacity(scrollTop > 0 ? 1 : 0);
          }}>
          {list}
        </Scrollbars>
      </> : <p className="empty">{t("This audio feed doesn’t have any eposides yet.")}</p>}
    </div>
    {product.episodes.length <= displayed ? "" : <button type="button" onClick={() => setDisplayed(displayed + 4)} className="btn-see-more">{t("See more")} <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-down.svg")} className="svg-icon" /></button>}
  </div>;
}

let resolveRecaptchaPromise,
  lastListener;

/**
 * Component `PaymentForm`.
 * @param {*} [props.show] - Modal's `show`.
 * @param {function} [props.onHide] - Modal's `onHide`.
 * @param {function} [props.onSuccess]
 * @param {function} [props.onFailure]
 * @param {Object} [props.product]
 * @param {Object} [props.author]
 * @param {Object} [props.elements]
 * @param {Object} [props.stripe]
 */
function PaymentForm({ show, onHide, product, author, onSuccess, onFailure, elements, stripe }) {
  let [loading, setLoading] = useState(false),
    [isFree, setIsFree] = useState(true),
    [priceValueNumeric, setPriceValueNumeric] = useState(null),
    [paymentRequest, setPaymentRequest] = useState(null),
    [paymentMethod, setPaymentMethod] = useState(null),
    formikRef = useRef(),
    [cardFeedback, setCardFeedback] = useState(null),
    recaptchaRef = useRef(null),
    { t } = useTranslation();

  useEffect(() => {
    if(product) {
      setIsFree(product.price.period == "free");
      setPriceValueNumeric(displayPrice(product, { amountOnly: true, t }));
    }
  }, [product, author, i18n.language]);

  useEffect(() => {
    if(stripe && product) {
      let pr = stripe.paymentRequest({
        country: "US",
        currency: product.price.currency || "usd",
        total: {
          label: product.name,
          amount: Math.round(product.price.amount * 100),
        },
      });
      pr.canMakePayment().then(result => {
        if(!result) return;

        pr.on("paymentmethod", ev => {
          setPaymentMethod(ev.paymentMethod);
          ev.complete("success");
          formikRef.current.handleSubmit();
        });

        setPaymentRequest(pr);
      });
    }
  }, [stripe, product]);

  let executeRecaptcha = async () => {
    return;
    //Disabled recaptcha due to cross-origin issues in pages with custom domains
    return new Promise((resolve, reject) => {
      resolveRecaptchaPromise = resolve;
      recaptchaRef.current.execute();
    });
  };

  let recaptchaCallback = token => {
    if(resolveRecaptchaPromise) resolveRecaptchaPromise(token);
  };

  let fail = reason => {
    setLoading(false);
    onHide();
    onFailure(typeof reason == "string" ? i18n.t(reason) : null);
    api.salesPage.failedPayment(product._id, lastListener);
    return null;
  };

  let success = res => {
    setLoading(false);
    onHide();
    onSuccess(res);
  };

  let grantAccess = async (values, payment = null) => {
    api.salesPage.createListener({ values, payment, product: product._id, recaptchaToken: await executeRecaptcha() })
      .then(res => {
        setLoading(false);

        if(!res || !res.success)
          return fail(values);

        success(res);
      })
      .catch(fail);
  };

  let processCustomer = async values => {
    let stripeCustomer = await api.salesPage.getCustomerByEmail(author._id, values.email, await executeRecaptcha());
    if(!stripeCustomer.data || !stripeCustomer.data.id)
      stripeCustomer = await api.salesPage.createCustomer(author._id, values, await executeRecaptcha());

    if(!stripeCustomer.data)
      return fail(stripeCustomer.message);

    return stripeCustomer;
  };

  let processOneTime = async values => {
    let stripeCustomer = await processCustomer(values);
    if(!stripeCustomer) return;

    api.salesPage.createPaymentIntentForDirectChange(author._id, product._id, stripeCustomer.data.id, await executeRecaptcha())
      .then(async intent => {
        if(!intent.data || !intent.data.client_secret)
          return fail(intent.message);

        await stripe.confirmCardPayment(intent.data.client_secret, {
          payment_method:
            paymentMethod
              ? paymentMethod.id
              : { card: elements.getElement(CardElement) }
        })
          .then(result => {
            if(result.error) {
              setLoading(false);
              if(result.error.type == "validation_error")
                return setCardFeedback(i18n.t("Please verify the credit card details."));
              return fail(result.error.message);
            }

            window.fbq?.("track", "Purchase", { currency: product.price.currency || "usd", value: product.price.amount });
            window.gtag?.("event", "purchase", { currency: product.price.currency || "usd", value: product.price.amount });

            grantAccess(values, result.paymentIntent.id);
          })
          .catch(fail);
      })
      .catch(fail);
  };

  let processSubscription = async values => {
    let stripeCustomer = await processCustomer(values);
    if(!stripeCustomer) return;

    let fn = async paymentMethodId => {
      let attachedPaymentMethod = await api.salesPage.attachPaymentMethod(author._id, paymentMethodId, stripeCustomer.data.id, await executeRecaptcha());
      if(!attachedPaymentMethod.data)
        return fail(attachedPaymentMethod.message);

      let subscription = await api.salesPage.createSubscription(stripeCustomer.data.id, author._id, paymentMethodId, product.stripe.priceId, await executeRecaptcha());
      if(!subscription.data)
        return fail(subscription.message);

      window.fbq?.("track", "Purchase", { currency: product.price.currency || "usd", value: product.price.amount });
      window.gtag?.("event", "purchase", { currency: product.price.currency || "usd", value: product.price.amount });

      grantAccess(values, subscription.data.id);
    };

    if(paymentMethod) {
      fn(paymentMethod.id);
    } else {
      stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)
      })
        .then(async paymentMethodData => {
          if(paymentMethodData.error) {
            setLoading(false);
            if(paymentMethodData.error.type == "validation_error")
              return setCardFeedback(i18n.t("Please verify the credit card details."));
            return fail(paymentMethodData.error.message);
          }
          fn(paymentMethodData.paymentMethod.id);
        })
        .catch(fail);
    }
  };

  let sendForm = (values, formik) => {
    if(loading) return;
    setLoading(true);

    lastListener = values;

    if(product.price.period == "free") {
      window.fbq?.("track", "Purchase", { currency: "USD", value: 0 });
      window.gtag?.("event", "purchase", { currency: "USD", value: 0 });

      return grantAccess(values);
    }

    if(product.price.period == "one-time")
      return processOneTime(values);

    processSubscription(values);
  };

  return <>
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      centered
      className="new-modals payment-modal modal-fullscreen-sm-down"
    >
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        }}
        validationSchema={isFree ? schemas.product.salesPage.getFreeAccess : schemas.product.salesPage.buy}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={true}
        onSubmit={sendForm}
        innerRef={formikRef}
      >
        {formik => <Form>
          <Modal.Header className="border-0">
            <button type="button" className="close back" onClick={onHide}><SVG src={toAbsoluteUrl("/media/def-image/back-2.svg")} className="svg-icon" /></button>
            <h1>{t("Payment")}</h1>
            <button type="button" className="close" onClick={onHide}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>
          </Modal.Header>
          <Modal.Body>
            <PurchaseSummary product={product} author={author} />

            <h2>{t("Your details")}</h2>
            <div className="row mb-6">
              <div className="col-md-6 mb-6 mb-md-0">
                <div className="form-group">
                  <label className="form-label">{t("First Name")} <em>*</em></label>
                  <input type="text" className={getInputClassName(formik, "firstName")} {...formik.getFieldProps("firstName")} />
                  {displayFeedback(formik, "firstName")}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">{t("Last Name")} <em>*</em></label>
                  <input type="text" className={getInputClassName(formik, "lastName")} {...formik.getFieldProps("lastName")} />
                  {displayFeedback(formik, "lastName")}
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label">{t("Email")} <em>*</em></label>
              <input type="text" className={getInputClassName(formik, "email")} {...formik.getFieldProps("email")} />
              {displayFeedback(formik, "email")}
            </div>
            <div className={"form-group" + (isFree ? " mb-0" : "")}>
              <label className="form-label">{t("Phone (optional)")}</label>
              <PhoneInput
                reset
                defaultCountry="US"
                value={formik.values.phone}
                onChange={value => {
                  if(value) {
                    formik.values.phone = value;
                  } else {
                    formik.values.phone = '';
                  }
                }}
                className={(formik.values.phone && !isValidPhoneNumber(formik.values.phone) ? "is-invalid" : "") + " form-control"}
              />
              {formik.values.phone && !isValidPhoneNumber(formik.values.phone)
                ? <div className="invalid-feedback-copy">{t("Invalid phone number.")}</div>
                : ""}
            </div>

            {isFree
              ? <></>
              : <>
                <h2>{t("Payment details")}</h2>
                {paymentRequest
                  ? <PaymentRequestButtonElement className="pay-button" options={{
                    paymentRequest,
                    style: {
                      paymentRequestButton: {
                        theme: "dark",
                        height: "45px"
                      }
                    }
                  }} />
                  : <></>}
                <div className="form-group mb-0">
                  <div className="form-control pt-4">
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: "14px",
                            fontFamily: "Poppins, Helvetica, 'sans-serif'",
                            color: "#181c32",
                            "::placeholder": {
                              color: "#181c32",
                            },
                          },
                          invalid: {
                            color: "#f1416c",
                          },
                        },
                      }}
                    />
                  </div>
                  {cardFeedback && <div className="invalid-feedback-copy">{cardFeedback}</div>}
                </div>
              </>}
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <div className="price">
              <label className="fs-7">{t("Total payment")}</label><br />
              <strong>
                {priceValueNumeric}
              </strong>
            </div>
            <div className="text-right flex-grow-1">
              {isFree
                ? <button type="submit" className={"btn btn-primary " + (loading ? "loading spinner" : "")}>{t("Get Access")}</button>
                : <button type="submit" className={"btn btn-primary " + (loading ? "loading spinner" : "")}>{t("Pay")} {priceValueNumeric}</button>}
            </div>
          </Modal.Footer>
        </Form>}
      </Formik>
    </Modal>

    <ReCaptcha
      ref={recaptchaRef}
      sitekey={process.env.REACT_APP_RECAPTCHA}
      verifyCallback={recaptchaCallback}
    />
  </>;
}

/**
 * Component `PaymentFormElements`.
 * @param {*} [props.show] - Modal's `show`.
 * @param {function} [props.onHide] - Modal's `onHide`.
 * @param {function} [props.onSuccess]
 * @param {function} [props.onFailure]
 * @param {Object} [props.product]
 * @param {Object} [props.author]
 */
function PaymentFormElements(props) {
  let elements = useElements(),
    stripe = useStripe();

  return <PaymentForm
    elements={elements}
    stripe={stripe}
    {...props} />;
}

/**
 * Component SalesPage
 * @param {Object} props 
 * @returns {Object}
 */
function SalesPage(props) {
  const { routes, productId, userId } = props,
    userProfileUrl = routes && routes.filter(r => r.kind == "profile")?.url,
    [product, setProduct] = useState(null),
    [author, setAuthor] = useState(null),
    [expandedReviews, setExpandedReviews] = useState([]),
    [expandedFaq, setExpandedFaq] = useState([]),
    [isOwn, setIsOwn] = useState(false),
    [successModal, setSuccessModal] = useState(false),
    [installUrl, setInstallUrl] = useState(null),
    [installEmail, setInstallEmail] = useState(null),
    [loading, setLoading] = useState(true),
    [buyModal, setBuyModal] = useState(false),
    [failedModal, setFailedModal] = useState(false),
    [failedReason, setFailedReason] = useState(null),
    [stripeObject, setStripeObject] = useState(null),
    { t, i18n } = useTranslation();

  useEffect(() => {
    setLoading(true);

    const filter = productId
      ? { id: productId }
      : { uri: props.match.params.uri };

    api.salesPage.getProduct(filter)
      .then(res => {
        if(!res.success || !res.data) window.location.href = "/404";
        setProduct(res.data.product);
        setAuthor(res.data.author);
        setLoading(false);
      });
    document.querySelector("#root").scrollTo({ top: 0, behavior: "smooth" });
  }, [props.match.params.uri]);

  useEffect(() => {
    setIsOwn(author && props.currentUser && author._id == props.currentUser._id);
  }, [props.currentUser, author]);

  useEffect(() => {
    (async () => {
      if(!stripeObject && author && author.stripeConnected && author.stripeConnected.stripeUserId) {
        let res = await loadStripe(process.env.REACT_APP_STRIPE_KEY, {
          stripeAccount: author.stripeConnected.stripeUserId
        });
        setStripeObject(res);
      }
    })();
  }, [author]);

  useEffect(() => {
    if(product && author)
      i18n.changeLanguage(product.salesPageLanguage || author.salesPageLanguage || "en");
  }, [product, author]);

  if(!product || !author) return <PublicPageLayout className="sales-page" loading />;

  let priceValue = displayPrice(product, { t }),
    stripeIsConnected = author.stripeConnected,
    canSell,
    authorUrl = getAuthorUrl(author);

  canSell = (stripeIsConnected && product.price.amount) || (product.price && product.price.period == "free");

  let isDraft = product.draft || product.systemDisabled || !author.enabled || !product.salesPageLive || !author.planFeatures.salesPage;

  document.title = product.name + (author.firstName ? " - " + author.firstName + (author.lastName ? " " + author.lastName : "") : "") + " - Hiro";

  let reviewAverage = null;
  if(product.salesPage && product.salesPage.reviews && product.salesPage.reviews.length) {
    product.salesPage.reviews.forEach(r => reviewAverage += r.rating);
    reviewAverage /= product.salesPage.reviews.length;
    reviewAverage = reviewAverage.toFixed(reviewAverage == Math.round(reviewAverage) ? 0 : 1);
  }

  let otherPodcasts = [];
  for(let i = 0; i < author.products.length; i++) {
    let p = author.products[i];
    if(p._id == product._id || p.draft || !p.salesPageLive)
      continue;

    if(product.categoryFilter && product.categoryId && p.categoryId != product.categoryId)
      continue;

    otherPodcasts.push(<div className="col-6 col-md-3" key={i}>
      <div className="product">
        <a href={getProductUrl(p)}>
          <ProductLogo product={p} className="icon" />
          <p className="title">{p.name}</p>
          <p className="description">{p.description}</p>
          {displayPrice(p.price, { jsx: true, t })}
        </a>
      </div>
    </div>);
  }

  let breadcrumb = (
    <>
      <a href={authorUrl}>
        {author.firstName ? author.firstName + (author.lastName ? " " + author.lastName : "") : author.email}
      </a> &nbsp;/ <span>{product.name}</span>
    </>
  );

  return <PublicPageLayout
    logo={author && author.logo && author.planFeatures.fullSalesPage && author.logo.imageUrl}
    author={author}
    className="sales-page"
    color={product.color}
    breadcrumb={breadcrumb}
    loading={loading}>

    <div className="col-12 breadcrumb-mobile d-md-none">{breadcrumb}</div>

    {isDraft && <div className="col-12">
      <div className="alert alert-warning d-flex align-items-center flex-row mb-10">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} className="svg-icon" />
        <div>
          <h4>Your page is in preview mode.</h4>
          Only you can see this page because {(() => {
            if(!author.planFeatures.salesPage) return "your current plan doesn’t include sales pages";
            if(product.draft && !product.salesPageLive) return "your product and the sales page are drafts";
            if(product.draft) return "your product is a draft";
            if(!product.salesPageLive) return "it's a draft";
          })()}. <a href={process.env.REACT_APP_SERVER_URL + "products/" + product._id + "/sales-page"}>Go to settings</a>
        </div>
      </div>
    </div>}
    {isOwn && (!product.price.period || (product.price.period != "free" && !stripeIsConnected)) ? <div className="col-12">
      <div className="alert alert-warning d-flex align-items-center flex-row mb-10">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} className="svg-icon" />
        <div>
          <h4>Set the price and connect Stripe to enable access to your product.</h4>
          You have to set the price and connect your Stripe account, or set the audio feed to Free instead, before you can get new listeners. <a href={process.env.REACT_APP_SERVER_URL + "products/" + product._id + "/sales-page"}>Go to settings</a>
        </div>
      </div>
    </div> : ""}

    <div className="col-lg-7">
      <div className="content-item product-details d-lg-flex">
        <ProductLogo product={product} />
        <div className="flex-grow-1 mt-1">
          <div className="summary d-flex align-items-center">
            <div className="flex-grow-1">
              {t("Created by:")} <a href={authorUrl} className="author-link themed">{author.firstName ? author.firstName + (author.lastName ? " " + author.lastName : "") : author.email}</a>
            </div>
            {product.categoryId && author.categories.length && (
              <div>
                <span>{t("Category:")} </span>
                <a href={authorUrl + "?category=" + product.categoryId} className="text-inherit">{author.categories.find(c => c._id == product.categoryId).name}</a>
              </div>
            )}
          </div>
          <h1>{product.name}</h1>
          <div className="product-description">{nl2br(product.description)}</div>

          {author.contactsMaxedOut
            ? <button type="button" className="btn btn-primary btn-buy disabled">{t("Show currently unavailable")}</button>
            : (
              <>
                {product.price.period == "free" && <button type="button" className="btn btn-primary btn-buy" onClick={e => setBuyModal(true)}>{t("Get Free Access")}</button>}

                {product.price.period != "free" && priceValue && <button type="button" disabled={!canSell} className="btn btn-primary btn-buy" onClick={e => setBuyModal(true)}>{t("Buy now for {{priceValue}}", { priceValue })}</button>}
              </>
            )}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <div className="content-item product-stats mb">
            {product.episodes.length} <label>{t("Episodes")}</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="content-item product-stats mb">
            {formatLength(product.totalDuration)} <label>{t("Length")}</label>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="content-item product-stats">
            <Stars value={reviewAverage || 0} />
            <label>{product.salesPage.reviews.length} {t("reviews")}</label>
          </div>
        </div>
      </div>

      {!product.episodes.length ? "" : <div className="content-item d-lg-none">
        <EpisodeList product={product} mobile />
      </div>}

      {product.salesPage.about && <div className="content-item">
        <h1>{t("About")}</h1>
        <div className="has-user-html" dangerouslySetInnerHTML={{ __html: product.salesPage.about }} />
      </div>}

      {!product.salesPage.reviews.length ? "" : <div className="content-item">
        <h1>{t("Testimonials")}</h1>
        <div className="reviews-list">
          {(() => {
            let list = [];
            for(let i = 0; i < product.salesPage.reviews.length; i++) {
              let item = product.salesPage.reviews[i];

              list.push(<div key={i} className={"review-item " + (expandedReviews.includes(i) ? "expanded" : "")}>
                <div className="d-flex align-items-center">
                  <div className="btn-expand-col d-sm-none">
                    <button type="button" className="btn-expand" onClick={() => {
                      if(expandedReviews.includes(i))
                        setExpandedReviews(expandedReviews.filter(x => x != i));
                      else
                        setExpandedReviews(expandedReviews.concat(i));
                    }} />
                  </div>
                  <div className="flex-1 mw0">
                    <div className="d-flex align-items-center">
                      <img src={item.pictureUrl || toAbsoluteUrl("/media/users/blank.png")} className="review-cover" />
                      <div className="flex-1 review-name"><strong>{item.firstName + (item.lastName ? " " + item.lastName : "")}</strong></div>
                      <div className="review-stars"><Stars value={item.rating} /></div>
                    </div>
                  </div>
                </div>
                <div className="expanded-text">{nl2br(item.review)}</div>
              </div>);
            }
            return list;
          })()}
        </div>
      </div>}

      {!product.salesPage.faq.length ? "" : <div className="content-item faq-list">
        <h1>{t("FAQ")}</h1>
        {(() => {
          let list = [];
          for(let i = 0; i < product.salesPage.faq.length; i++) {
            let item = product.salesPage.faq[i];

            list.push(<div key={i} className={"faq-item " + (expandedFaq.includes(i) ? "expanded" : "")}>
              <div className="d-flex align-items-center">
                <div className="btn-expand-col">
                  <button type="button" className="btn-expand" onClick={() => {
                    if(expandedFaq.includes(i))
                      setExpandedFaq(expandedFaq.filter(x => x != i));
                    else
                      setExpandedFaq(expandedFaq.concat(i));
                  }} />
                </div>
                <div className="flex-1">
                  <strong>{item.question}</strong>
                </div>
              </div>
              <div className="expanded-text">{nl2br(item.response)}</div>
            </div>);
          }
          return list;
        })()}
      </div>}

      <div className="content-item author-summary">
        <div className="author-bio">
          <a href={authorUrl} className="profile-pic">
            <img src={author.pictureUrl || toAbsoluteUrl("/media/users/blank.png")} />
          </a>
          <div className="author-bio-bg">
            <h1>
              <a className="themed" href={authorUrl}>{author.firstName ? author.firstName + (author.lastName ? " " + author.lastName : "") : author.email}</a>
            </h1>
            {author.website && isValidUrl(author.website) && <a href={author.website} target="_blank" className="themed">{author.website.replace(/^https?:\/\//i, "")}</a>}
            <AuthorSocialIcons author={author} />
          </div>
        </div>

        {author.bio && <>
          <h2>{t("About me")}</h2>
          <div dangerouslySetInnerHTML={{ __html: author.bio }} />
        </>}

        {!otherPodcasts.length ? "" : <>
          <h2>{t("My other private audio feeds")}</h2>
          <div className="row products-list">
            {otherPodcasts}
          </div>
        </>}
      </div>
    </div>

    {!product.episodes.length ? "" : <div className="d-none d-lg-block col-md-5">
      <div className="content-item">
        <EpisodeList product={product} desktop />
        <div className="hr" />
        <div className="buy-button-container">
          {author.contactsMaxedOut
            ? <button type="button" className="btn btn-primary btn-buy disabled">{t("Show currently unavailable")}</button>
            : (
              <>
                {product.price.period == "free" && <button type="button" className="btn btn-primary btn-buy" onClick={e => setBuyModal(true)}>{t("Get Free Access")}</button>}

                {priceValue && product.price.period != "free" && <button type="button" disabled={!canSell} className="btn btn-primary btn-buy" onClick={e => setBuyModal(true)}>{t("Buy now for {{priceValue}}", { priceValue })}</button>}

                {priceValue && product.price.period != "free" && <img src={toAbsoluteUrl("/media/def-image/guarantee.svg")} alt="Payment method" />}
              </>
            )}
        </div>
      </div>
    </div>}

    {product.price.period == "free"
      ? <PaymentForm
        show={buyModal}
        onHide={() => setBuyModal(false)}
        product={product}
        author={author}
        onSuccess={res => {
          setInstallUrl(res.installUrl);
          setInstallEmail(res.email);
          setSuccessModal(true);
        }}
        onFailure={reason => {
          setFailedReason(reason);
          setFailedModal(true);
        }} />
      : <></>}

    {product.price.period != "free" && stripeObject
      ? <Elements stripe={stripeObject}>
        <PaymentFormElements
          show={buyModal}
          onHide={() => setBuyModal(false)}
          product={product}
          author={author}
          onSuccess={res => {
            setInstallUrl(res.installUrl);
            setInstallEmail(res.email);
            setSuccessModal(true);
          }}
          onFailure={() => setFailedModal(true)} />
      </Elements>
      : <></>}

    <Modal
      show={successModal}
      onHide={() => setSuccessModal(false)}
      size="lg"
      centered
      className="new-modals success-modal modal-fullscreen-sm-down"
    >
      <Modal.Body className="text-center">
        <button type="button" className="close" onClick={e => setSuccessModal(false)}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>
        <div>
          <img src={toAbsoluteUrl("/media/def-image/purchase-success.png")} />
          <h1>{t("Thank you!")}</h1>
          <p>{t("You’ve successfully purchased {{productName}} from {{author}}. We sent you an email to {{installEmail}} with access instructions.", {
            productName: product.name,
            author: author.firstName ? author.firstName + (author.lastName ? " " + author.lastName : "") : author.email,
            installEmail
          })}</p>
          {!isMobile
            ? <>
              <p><strong>{t("Point your phone’s camera at the code below to access the private podcast.")}</strong></p>
              <QRCode value={installUrl} size="76" />
            </>
            : <></>
          }
          <div className="purchase">
            <h2>{t("Purchased item")}</h2>
            <PurchaseSummary product={product} author={author} className="round" />
          </div>
          <button type="button" className="btn btn-primary" onClick={e => window.location.href = installUrl}>{t("Access Private Feed Now")}</button>
        </div>
      </Modal.Body>
    </Modal>

    <Modal
      show={failedModal}
      onHide={() => setFailedModal(false)}
      size="lg"
      centered
      className="new-modals success-modal modal-fullscreen-sm-down"
    >
      <Modal.Body className="text-center">
        <button type="button" className="close" onClick={e => setFailedModal(false)}><SVG src={toAbsoluteUrl("/media/def-image/close.svg")} className="svg-icon" /></button>
        <div>
          <img src={toAbsoluteUrl("/media/def-image/purchase-failed.png")} />
          <h1>{t("Ohh no, something went wrong with your payment")}</h1>
          <p>{t(failedReason ? failedReason : "Check your credit card details and try one more time.")}</p>
          <button type="button" className="btn btn-primary" onClick={e => {
            setFailedModal(false);
            setBuyModal(true);
          }}>{t("Try one more time")}</button><br />
          <button type="button" className="btn btn-transparent text-muted" onClick={e => setFailedModal(false)}>{t("Cancel")}</button>
        </div>
      </Modal.Body>
    </Modal>
  </PublicPageLayout>;
}

export default injectIntl(connect(
  store => ({
    currentUser: store.auth.user
  }),
  dispatch => ({
    dispatch
  })
)(SalesPage));
