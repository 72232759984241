import React, { Fragment, useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import _ from "lodash";
import MessageField from "../../../layout/components/MessageField";
import utils from "../utils";
import CustomSelect from "../../../layout/components/CustomSelect";
import { SegmentedMessage } from "sms-segments-calculator";
import { getSmsBlockLength, isValidSmsText } from "../../../helpers/utils";
import { PhoneNumberModalEvents } from "../../modals/PhoneNumberModal";
import { NeedsUpgradeEvents } from "../../modals/NeedsUpgrade";
import { getInputClassName, toastMessage } from "../../../helpers";
import api from "../../../../redux/api";

let debounceTimer;

function SendSMS({ automationId, submit, user, name, formik, saving, trigger, triggerIndex, actionIndex, remove, setPreviewBody, setPreviewSettings }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name),
    [cost, setCost] = useState(null),
    [attachmentIsValid, setAttachmentIsValid] = useState(),
    [attachmentIsLoading, setAttachmentIsLoading] = useState(),
    [attachmentError, setAttachmentError] = useState(),
    attachmentValidationTimerRef = useRef(),
    attachmentInputRef = useRef();

  let updatePreview = () => {
    let message = get("message"),
      attachment = get("attachment");

    let replace = v => v.replace(/\[name(\|.+?)?\]/g, "John Carter")
      .replace(/\[email\]/g, "john@test.com")
      .replace(/\[install-url\]/g, "https://go.hiro.fm/abc")
      .replace(/\[first-name(\|.+?)?\]/g, "John");

    let body = (
      <>
        <div className={"message-out animated " + (message || attachment ? "" : " empty")} key={Math.random()}>
          {!!attachment && (
            <div className="attachment" style={{ backgroundImage: "url(" + attachment + ")" }} />
          )}
          {!!message && (
            <>
              <em>{(user && user.firstName) || "You"}</em>
              {replace(message).split(/\n/g).map((line, i) => <Fragment key={i}>{line}<br /></Fragment>)}
            </>
          )}
          {!message && !!attachment && (
            <>
              <em className="mb-0">{(user && user.firstName) || "You"}</em>
            </>
          )}
          {!message && !attachment && (
            <>
              <span />
              <span />
            </>
          )}
        </div>
      </>
    );

    setPreviewBody(body);
  };

  let updateCost = () => {
    let message = get("message");

    setCost(message && message.trim()
      ? new SegmentedMessage(message).segmentsCount
      : null);
  };

  function attachmentOnInput() {
    clearTimeout(attachmentValidationTimerRef.current);
    setAttachmentIsValid(null);
    setAttachmentError(null);
    set("attachment", "");

    let value = attachmentInputRef.current.value;
    if(value)
      attachmentValidationTimerRef.current = setTimeout(() => validateAttachment(value), 500);
  }

  async function validateAttachment(url) {
    setAttachmentIsLoading(true);

    let res = await api.generic.validateAttachment(url);

    setAttachmentIsLoading(false);

    if(!res)
      return toastMessage.error("Unable to connect to the server.");

    if(!res.success) {
      setAttachmentIsValid(false);
      setAttachmentError(res.error || "Unable to validate the image.");
      return;
    }

    if(res.size > 600) {
      setAttachmentIsValid(false);
      setAttachmentError("The image is too large. Please use an image smaller than 600KB.");
      return;
    }

    setAttachmentIsValid(true);
    set("attachment", url);
  }

  useEffect(() => {
    setPreviewSettings({
      show: true,
      className: "chat",
      title: "SMS Message Preview"
    });

    updatePreview();

    return () => setPreviewSettings({ show: false });
  }, []);

  useEffect(() => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(updatePreview, 500);

    updateCost();
  }, [formik.values]);

  useEffect(() => {
    if(!user)
      return;

    if(!user.phoneNumbers.length)
      PhoneNumberModalEvents.dispatchShowSms();
  }, [user]);

  return (
    <>
      <div className="form-group">
        <label className="d-block form-label">When to send SMS</label>
        <select className="custom-select w-auto" {...props("when")}>
          <option value="immediately">Immediately</option>
          <option value="delay">Delay send until</option>
        </select>
      </div>

      {get("when") == "delay" && (
        <div className="form-group -mt">
          <div className="d-flex align-items-center">
            <input type="number" className="form-control hide-spinner w-90px mr-3" min="1" step="1" {...props("delay")} onKeyDown={utils.disableIntro} />
            <select className="custom-select w-auto mr-3" {...props("period")}>
              <option value="minutes">minutes</option>
              <option value="hours">hours</option>
              <option value="days">days</option>
            </select>
            <span>after event is triggered</span>
          </div>
          {error("delay") && touched("delay") && <div className="field-error">{error("delay")}</div>}
        </div>
      )}

      {/*<div className="form-group">
        <label className="d-block">Recipients</label>
        <CustomSelect noSelectionText="Filter by tag" multiple />
      </div>*/}

      <div className="form-group">
        <div className="row align-items-center mb-2">
          <div className="col">
            <label className="m-0 form-label">SMS Message</label>
          </div>
          {cost !== null && <div className="col text-right sms-cost">Message costs <strong>{cost}</strong> credit</div>}
        </div>
        <MessageField className="fw" formik={formik} name={name + ".message"} emoji personalization />
        {error("message") && <div className="field-error">{error("message")}</div>}
      </div>

      <div className="form-group attachment-field-group">
        <label className="form-label">Embed Image or Giphy</label>
        {attachmentIsLoading && <div className="spinner spinner-sm attachment-spinner" />}
        <input type="text" className={"form-control " + (attachmentIsValid === true ? "is-valid" : "") + (attachmentIsValid === false ? "is-invalid" : "")} defaultValue={get("attachment")} ref={attachmentInputRef} onInput={attachmentOnInput} />
        {attachmentError && <div className="field-error">{attachmentError}</div>}
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(SendSMS)
);