import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, isValidUrl } from "../../_metronic/_helpers";
import moment from "moment";
import actions from "../../redux/actions";
import api from "../../redux/api";
import i18n from "../i18n";

export async function updateCurrentUser(dispatch) {
    dispatch(actions.auth.fulfillUser((await api.auth.getUserByToken(true)).data));
}

export async function updateProducts(dispatch) {
    dispatch(actions.product.setProducts(await api.product.getProducts(true)));
}

export const numberFormat = v =>
    typeof v != "number" || isNaN(v) ? "0" : v.toLocaleString(undefined, { minimumFractionDigits: v === Math.round(v) ? 0 : 2, maximumFractionDigits: 2 });

export const smsCharset = "@Δ0¡P¿p£_!1AQaq$Φ\"2BRbr¥Γ#3CScsèΛ¤4DTdtéΩ%5EUeuùΠ&6FVfvìΨ'7GWgwòΣ(8HXhxÇΘ)9IYiyΞ*:JZjzØ+;KÄkäøÆ,<LÖlöæ-=MÑmñÅß.>NÜnüåÉ/?O§oà \r\n"; //Actual GSM 7-bit basic character set

let _smsCharsetRegex = /^[@0¡P¿p£_!1AQaq\$\"2BRbr¥#3CScsè4DTdté%5EUeuù&6FVfvì'7GWgwò\(8HXhxÇ\)9IYiy\*:JZjz\+;KÄkä,<LÖlö\-=MÑmñ\.>NÜnüÉ\/\?Ooà \r\n]*$/;
export const smsCharsetRegex = _smsCharsetRegex;

/**
 * 
 */
export function isValidSmsText(text) {
    return typeof text == "string" && !!text.match(_smsCharsetRegex);
};

/**
 * 
 */
export function isSmsBaseCharset(text) {
    return typeof text != "string" || isValidSmsText(text);
}

/**
 * 
 */
export function getSmsBlockLength(text) {
    return isSmsBaseCharset(text)
        ? 160
        : 70;
}

/**
 * 
 */
export function getSearchRegExp(str) {
    try {
        return new RegExp(str.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&"), "i");
    } catch(err) {
        return null;
    }
}

/**
 * Returns the printable price of the product as JSX.
 * @param {Object} product - Product object.
 * @param {boolean} [long=false] - Return long format.
 * @param {boolean} [amountOnly=false] - Returns the amount only.
 * @returns {(Object|null)}
 */
export function displayPrice(product, { jsx, long, amountOnly, t } = {}) {
    let price = product.price,
        value = "",
        period = "",
        periodLong = "";

    if(!price) return null;

    if(price.period == "free")
        return jsx
            ? <span className="price">{t("Free")}</span>
            : t("Free");

    if(!price.amount)
        return null;

    let currency = {
        "usd": "$",
        "eur": "€",
        "gbp": "£",
        "aud": "AU$",
        "pln": "zł",
    }[ price.currency || "usd" ];
    value = currency + price.amount;

    period = {
        "free": "",
        "one-time": "",
        "weekly": t("/ week"),
        "monthly": t("/ month"),
        "annual": t("/ year")
    }[ price.period ];

    periodLong = {
        "free": "",
        "one-time": t("one-time payment"),
        "weekly": t("per week"),
        "monthly": t("per month"),
        "annual": t("per year")
    }[ price.period ];

    if(jsx)
        return !amountOnly && long
            ? <span className="price">{value}<br /><small className="text-muted">{periodLong}</small></span>
            : <span className="price">{value} {amountOnly ? "" : period}</span>;

    return !amountOnly && long
        ? value + " " + periodLong
        : value + (amountOnly ? "" : " " + period);
};

export function trim(str, chr = null) {
    str = (str || "").trim();
    if(chr) {
        while(str.length && str.substr(0, 1) == chr)
            str = str.substr(1);
        while(str.length && str.substr(-1) == chr)
            str = str.substr(0, str.length - 1)
    }
    return str;
}

/**
 * Returns the author's URL.
 * @param {Object} author 
 * @param {boolean} [full]
 * @returns {string}
 */
export function getAuthorUrl(author) {
    if(!author || !author.publicUrl)
        return null;
    return author.publicUrl.customDomain && author.publicUrl.host
        ? trim("https://" + author.publicUrl.host + "/" + trim(author.publicUrl.uri, "/"), "/")
        : process.env.REACT_APP_LOCAL_URL + "pro/" + trim(author.publicUrl.uri, "/");
};

/**
 * 
 * @param {Object} product 
 * @param {boolean} [full]
 * @returns {string}
 */
export function getProductUrl(product) {
    if(!product)
        return null;
    return product.customDomain && product.host
        ? trim("https://" + product.host + "/" + trim(product.publicUri, "/"), "/")
        : process.env.REACT_APP_LOCAL_URL + "channel/" + trim(product.publicUri, "/");
};

/**
 * Component Stars.
 * @param {number} [props.value]
 * @returns {Object}
 */
export function Stars(props) {
    let stars = [];
    for(let i = 1; i <= 5; i++)
        stars.push(<SVG key={i} src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} className={"svg-icon " + (props.value >= i ? "star-yellow" : "star-gray")} />);
    return stars;
};

/**
 * Formats the given number of seconds as `m:i` or `h:m`.
 * @param {number} v
 * @param {boolean} keepMinutes
 * @returns {string}
 */
export function formatLength(v, keepMinutes = false) {
    if(v === 0) return "0:00";
    if(!v || isNaN(v)) return "-:--";

    v = Math.round(v);

    if(v < 60) return "0:" + v.toString().padStart(2, "0");

    if(v < 3600 || keepMinutes) {
        let min = Math.floor(v / 60);
        return min + ":" + (v - min * 60).toString().padStart(2, "0");
    }

    let hours = Math.floor(v / 3600),
        min = ((v - hours * 3600) / 60).toFixed(0);
    return hours + ":" + min.padStart(2, "0") + " h";
};

/**
 * Component AuthorSocialIcons
 * @param {Object} [product.author]
 * @returns {Object}
 */
export function AuthorSocialIcons(props) {
    let { author } = props,
        properties = [
            //[property,icon,svg]
            [ "facebook", "facebook" ],
            [ "instagram", "instagram" ],
            [ "twitter", "twitter" ],
            [ "linkedin", "linkedin" ],
            [ "youtube", "youtube" ],
            [ "tiktok", "tiktok", true ],
            [ "spotify", "spotify" ],
            [ "pinterest", "pinterest" ]
        ],
        icons = [];

    if(author.social)
        for(let prop of properties)
            if(author.social[ prop[ 0 ] ] && isValidUrl(author.social[ prop[ 0 ] ]))
                icons.push(<a key={prop[ 0 ]} href={author.social[ prop[ 0 ] ]} target="_blank">
                    {prop[ 2 ] === true
                        ? <SVG src={toAbsoluteUrl("/media/svg/logos/" + prop[ 1 ] + ".svg")} className="svg-icon svg-icon-social" />
                        : <i className={"fab fa-" + prop[ 1 ]} />
                    }</a>);

    if(icons.length) return <div className="social">{icons}</div>;
    return <></>;
};

export function isModalOpen() {
    return document.querySelectorAll(".modal.show").length > 0;
};

export function isPublicPage(path) {
    return /^\/(install|app-install|rss-import-.+?|validation|app-social-login|reset-app-password|pro|channel|embed|admin|auth|error|logout)\/?/.test(path);
};

export function dataURLtoFile(url, fileName) {
    let arr = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([ u8arr ], fileName, { type: mime });
};

/**
 * 
 */
export function relativeDate(date) {
  let currentDate = moment(new Date()),
    transformedDate = moment(date),
    diffMinutes = currentDate.diff(transformedDate, "minutes") > 0 ? currentDate.diff(transformedDate, "minutes") : null,
    diffMinutesLabel = diffMinutes && diffMinutes + " min ago",
    diffHours = currentDate.diff(transformedDate, "hours") > 0 ? currentDate.diff(transformedDate, "hours") : null,
    diffHoursLabel = diffHours && (diffHours > 1 ? diffHours + " hours ago" : diffHours + " hour ago"),
    diffDays = currentDate.diff(transformedDate, "days") > 0 ? currentDate.diff(transformedDate, "days") : null,
    diffDaysLabel = diffDays && (diffDays > 1 ? diffDays + " days ago" : "Yesterday"),
    diffMonths = currentDate.diff(transformedDate, "months") > 0 ? currentDate.diff(transformedDate, "months") : null,
    diffMonthsLabel = diffMonths && (diffMonths > 1 ? diffMonths + " months ago" : diffMonths + " month ago");

  if(diffMonths) {
    return diffMonthsLabel;
  } else if(diffDays) {
    return diffDaysLabel;
  } else if(diffHours) {
    return diffHoursLabel;
  } else if(diffMinutes) {
    return diffMinutesLabel;
  } else if(diffMinutes === null) {
    return "1 min ago";
  }
};

/**
 * 
 */
export function formatDurationAsText(seconds, short = false) {
    if(isNaN(parseInt(seconds))) return null;

    if(!seconds) return "0 Min";

    let hours = Math.floor(seconds / 3600),
        minutes = Math.round((seconds % 3600) / 60);

    if(short) 
        return hours > 0
        ? hours + " Hr " + minutes + " Min"
        : (
            minutes > 0
            ? minutes + " Min"
            : "< 1 Min"
        );
            
    return hours > 0
        ? hours + " hours, " + minutes + " minutes"
        : (
            minutes > 0
            ? minutes + " minutes"
            : Math.round(seconds) + " seconds"
        );
}