import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import actions from "../../../../redux/actions";
import _ from "lodash";
import MessageField from "../../../layout/components/MessageField";
import utils from "../utils";
import Editor from "../../../layout/components/Editor";
import EmailAccountModal from "../../account/UserProfile/EmailAccountModal";

function SendEmail({ automationId, submit, name, formik, saving, trigger, triggerIndex, actionIndex, remove, setPreviewBody, setPreviewSettings, isNew }) {
  let { set, get, error, props, touched } = utils.useFormikUtils(formik, name),
    user = useSelector(state => state.auth.user, shallowEqual),
    [showAddEmailModal, setShowAddEmailModal] = useState(false);

  function addEmailOnClick(ev) {
    ev.preventDefault();
    setShowAddEmailModal(true);
  }

  useEffect(() => {
    if(isNew)
      set("when", "immediately");

    if(isNew || !get("sendEmailFromId"))
      set("sendEmailFromId", user.emailAddresses.find(item => item.default)?._id);
  }, [isNew]);

  return (
    <>
      <div className="form-group">
        <label className="d-block form-label">When to send email</label>
        <select className="custom-select w-auto" {...props("when")}>
          <option value="immediately">Immediately</option>
          <option value="delay">Delay send until</option>
        </select>
      </div>

      {get("when") == "delay" && (
        <div className="form-group -mt">
          <div className="d-flex align-items-center">
            <input type="number" className="form-control hide-spinner w-90px mr-3" min="1" step="1" {...props("delay")} onKeyDown={utils.disableIntro} />
            <select className="custom-select w-auto mr-3" {...props("period")}>
              <option value="minutes">minutes</option>
              <option value="hours">hours</option>
              <option value="days">days</option>
            </select>
            <span>after event is triggered</span>
          </div>
          {error("delay") && touched("delay") && <div className="field-error">{error("delay")}</div>}
        </div>
      )}

      <div className="form-group">
        <label className="form-label">Sending Email Address</label>
        {!user.emailAddresses.length
          ? (
            <div className="text-muted py-3">
              You don’t have an email service connected.
              <a href="#" onClick={addEmailOnClick} className="fw-bold ml-4">Connect an email service</a>
            </div>
          )
          : (
            <select className="custom-select" {...props("sendEmailFromId")}>
              {user.emailAddresses.map(item => <option value={item._id} key={item._id}>{item.email}</option>)}
            </select>
          )}
      </div>

      <div className="form-group">
        <label className="form-label">Email Title</label>
        <input type="text" className="form-control" {...props("title")} onKeyDown={utils.disableIntro} />
        {error("title") && touched("title") && <div className="field-error">{error("title")}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">Email Message</label>
        <Editor className="fw" formik={formik} name={name + ".message"} emoji media personalization />
        {error("message") && touched("message") && <div className="field-error">{error("message")}</div>}
      </div>

      <div className="form-group">
        <label className="form-label">Send Notification Email</label>
        <input type="text" className="form-control" {...props("email")} onKeyDown={utils.disableIntro} />
        {error("email") && touched("email") && <div className="field-error">{error("email")}</div>}
      </div>

      <EmailAccountModal show={showAddEmailModal} onDismiss={() => setShowAddEmailModal(false)} onCreate={email => set("sendEmailFromId", email._id)} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      dispatch
    })
  )(SendEmail)
);