import React from "react";
import { Link } from "react-router-dom";

function ProductNavigation({ product, active }) {
    return (
        <>
            <h1>{product.name}</h1>
            <div className="navigation-tabs">
                <Link to={"/products/" + product._id} className={"tab " + (active == "overview" ? "active" : "")}>Overview</Link>
                <Link to={"/products/" + product._id + "/settings"} className={"tab " + (active == "settings" ? "active" : "")}>Settings</Link>
                <Link to={"/products/" + product._id + "/sales-page"} className={"tab " + (active == "money" ? "active" : "")}>Sales Page</Link>
            </div>
        </>
    );
}

export default ProductNavigation;