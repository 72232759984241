import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Form, Formik, useFormik } from "formik";
import actions from "../../../../redux/actions";
import schemas from "../../../schemas";
import api from "../../../../redux/api";
import { getSearchRegExp, toastMessage, updateCurrentUser } from "../../../helpers";
import SettingsNavigation from "../../../layout/navigation/SettingsNavigation";
import UnsavedFormGuard from "../../../layout/components/UnsavedFormGuard";
import TagsInput from "react-tagsinput";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Dropdown, Modal } from "react-bootstrap";
import ConfirmModal from "../../modals/ConfirmModal";
import moment from "moment";
import useTimeout from "../../../helpers/useTimeout";
import { NeedsUpgradeEvents } from "../../modals/NeedsUpgrade";
import EmailAccountModal from "./EmailAccountModal";
import ProfileNavigation from "../../../layout/navigation/ProfileNavigation";
import { checkLimits } from "../../../plans";

function EmailAccounts() {
  const user = useSelector(state => state.auth.user, shallowEqual),
    [loading, setLoading] = useState(false),
    dispatch = useDispatch(),
    [showEditModal, setShowEditModal] = useState(false),
    [editEmailId, setEditEmailId] = useState(),
    [confirmDelete, setConfirmDelete] = useState(null),
    [details, setDetails] = useState({}),
    [detailsLoading, setDetailsLoading] = useState(false),
    [showDetailsModal, setShowDetailsModal] = useState(false);

  async function deleteItem(id) {
    setLoading(true);
    setConfirmDelete(null);

    let res = await api.user.deleteEmailAddress(id);

    if(!res || !res.success) {
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
      setLoading(false);
      return;
    }

    toastMessage.success("The email address has been removed.");

    await updateCurrentUser(dispatch);

    setLoading(false);
  }

  async function addEmailOnClick(ev) {
    ev.preventDefault();

    if(await checkLimits.canAddEmailAddress(user))
      setShowEditModal(true);
  }

  async function editEmailOnClick(ev, item) {
    ev.preventDefault();
    setEditEmailId(item._id);
    setShowEditModal(true);
  }

  async function editModalOnDismiss() {
    setShowEditModal(false);
    setEditEmailId(null);
    updateCurrentUser(dispatch);
  }

  async function loadDetails(item, type) {
    let modalContent = {
      email: item.email,
      typeHeader: {
        shows: "Show",
        automations: "Automation"
      }[type]
    };

    setDetails(modalContent);
    setDetailsLoading(true);
    setShowDetailsModal(true);

    let res = await api.user.getEmailAddressDetails(item._id, type);

    setDetailsLoading(false);

    if(!res || !res.success) {
      toastMessage.error((res && res.error) || "Unable to connect to the server.");
      setShowDetailsModal(false);
      return;
    }

    setDetails({
      ...modalContent,
      data: res.data
    });
  }

  function showsCountOnClick(ev, item) {
    ev.preventDefault();
    loadDetails(item, "shows");
  }

  function automationsCountOnClick(ev, item) {
    ev.preventDefault();
    loadDetails(item, "automations");
  }

  useEffect(() => {
    updateCurrentUser(dispatch);

    if(window.location.hash.match(/google_at/)) {
      let match = window.location.hash.match(/email-id-([^&]+)/);
      if(match)
        setEditEmailId(match[1]);
      setShowEditModal(true);
    }
  }, []);

  return (
    <>
      <ProfileNavigation active="email-accounts" />
      <EmailAccountModal show={showEditModal} emailId={editEmailId} onDismiss={editModalOnDismiss} />

      {!user.emailAddresses.length
        ? (
          <div className="container-inner email-addresses-page empty-state">
            <SVG src="/media/def-image/empty-domains.svg" />
            <h3>Connect Your Email Account</h3>
            <p>Connect your email accounts to send access and automated emails from your own server.</p>
            <a href="#" onClick={addEmailOnClick} className="btn btn-primary">Add Email</a>
          </div>
        )
        : (
          <div className="container-inner email-addresses-page">
            <div className="card p-9">
              <div className="card-toolbar align-items-center">
                <h2 className="mb-0">Email Addresses</h2>
                <div className="fill" />
                <a href="#" onClick={addEmailOnClick} className="btn btn-primary mr-6">+ Email</a>
              </div>

              {loading
                ? <div className="loading-block spinner" />
                : (
                  <div className="table-responsive">
                    <table className="table table-head-custom table-vertical-center">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Shows</th>
                          <th>Automations</th>
                          <th width="10"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {user.emailAddresses.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {item.email} {item.default && <span className="badge blue-badge">Default</span>}
                            </td>
                            <td>
                              <span className={"badge " + (item.status == "failed" ? "red-badge" : "green-badge")}>
                                {item.status == "failed" ? "Failed" : "Connected"}
                              </span>
                            </td>
                            <td>
                              {item.showsCount > 0
                                ? <a href="#" onClick={ev => showsCountOnClick(ev, item)}>{item.showsCount}</a>
                                : "0"}
                            </td>
                            <td>
                              {item.automationsCount > 0
                                ? <a href="#" onClick={ev => automationsCountOnClick(ev, item)}>{item.automationsCount}</a>
                                : "0"}
                            </td>
                            <td className="pr-0 text-right">
                              <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                                <Dropdown.Toggle className={loading == "item_" + item._id ? " loading spinner spinner-dark" : ""}>
                                  <SVG src="/media/def-image/icons/menu-2.svg" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                  <Dropdown.Item onClick={ev => editEmailOnClick(ev, item)} className="dropdown-item">
                                    <div className="icon">
                                      <SVG src="/media/def-image/icons/edit-2.svg" />
                                    </div>
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item href="#" onClick={() => setConfirmDelete(item)}>
                                    <div className="icon">
                                      <SVG src="/media/def-image/icons/delete.svg" />
                                    </div>
                                    Remove
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
        )}

      <ConfirmModal
        show={!!confirmDelete}
        message={<>Do you want to permanently delete this email address?</>}
        onConfirm={() => deleteItem(confirmDelete._id)}
        onCancel={() => setConfirmDelete(null)} />

      <Modal centered className="new-modals email-address-details-modal" size="lg" onHide={() => setShowDetailsModal(false)} show={showDetailsModal}>
        <Modal.Body>
          <button type="button" className="close" onClick={() => setShowDetailsModal(false)}><SVG src="/media/def-image/close-sm.svg" className="svg-icon" /></button>

          <h1>{details.email}</h1>

          {detailsLoading
            ? <div className="loading-block spinner spinner-lg" />
            : (
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center">
                  <thead>
                    <tr>
                      <th>{details.typeHeader}</th>
                      <th width="10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(details.data || []).map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item.name}
                        </td>
                        <td>
                          <Link to={item.url}>View</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default connect(null, actions.auth)(EmailAccounts);
