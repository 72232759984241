import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import actions from "../../../redux/actions";
import SVG from "react-inlinesvg";
import api from "../../../redux/api";
import moment from "moment";
import { ProductLogo } from "../../../_metronic/layout/components/productLogo";
import ConfirmModal from "../modals/ConfirmModal";
import { getSearchRegExp, toastMessage } from "../../helpers";
import { Dropdown } from "react-bootstrap";
import { checkLimits } from "../../plans";
import PhoneNumberDropdown from "../../layout/dropdowns/PhoneNumberDropdown";

const statusLabels = {
  "failed": "Failed",
  "draft": "Draft",
  "paused": "Paused",
  "pending": "Pending",
  "active": "Sending",
  "scheduled": "Scheduled",
  "completed": "Completed"
};

const statusColors = {
  "failed": "red-badge",
  "draft": "gray-badge",
  "paused": "blue-badge",
  "pending": "yellow-badge",
  "active": "yellow-badge",
  "scheduled": "yellow-badge",
  "completed": "green-badge"
};

let refreshTimer;

function Campaigns({ user, products, dispatch, fulfillUser, phoneNumber }) {
  const [loading, setLoading] = useState(),
    [actionLoading, setActionLoading] = useState(),
    [list, setList] = useState([]),
    [selected, setSelected] = useState([]),
    [showConfirmDelete, setShowConfirmDelete] = useState(false),
    [search, setSearch] = useState(null),
    history = useHistory();

  async function loadList() {
    if(!phoneNumber)
      return;

    setLoading(true);
    let res = await api.sms.listCampaigns(phoneNumber.kind);
    setLoading(false);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    setList(res.data);
  }

  function selectedOnChange(ev) {
    let items = selected.filter(i => i != ev.target.value);
    if(ev.target.checked)
      items.push(ev.target.value);
    setSelected(items);
  }

  function deleteOnClick(ev) {
    setShowConfirmDelete(true);
  }

  function deleteItem(ev, item) {
    ev.preventDefault();
    setShowConfirmDelete(item._id);
  }

  async function confirmDelete() {
    setShowConfirmDelete(false);

    if(showConfirmDelete === true) {
      setLoading(true);
      for(let item of selected)
        await api.sms.deleteCampaign(item);
      setSelected([]);
    } else {
      setActionLoading("menu_" + confirmDelete);
      await api.sms.deleteCampaign(showConfirmDelete);
      setActionLoading(false);
    }

    loadList();

    let res = await api.auth.getUserByToken(true);
    if(res)
      dispatch(fulfillUser(res.data));
  }

  async function handleSearch(ev) {
    setSearch(ev.target.value);
  }

  function filter(list, search) {
    if(!search || !search.trim())
      return list;

    let r = getSearchRegExp(search);

    return list.filter(item => r.test(item.text) || !!item.products.find(p => r.test(p.name)) || !!item.tags.find(t => r.test(t)));
  }

  async function togglePauseItem(ev, item) {
    ev.preventDefault();
    setActionLoading("menu_" + item._id);

    let res = await api.sms.toggleCampaignActiveStatus(item._id);

    setActionLoading(null);

    if(!res || !res.success)
      return toastMessage.error((res && res.error) || "Unable to connect to the server.");

    toastMessage.success("Campaign updated!");
    loadList();
  }

  async function createOnClick(ev) {
    ev.preventDefault();

    if(await checkLimits.canAddCampaign(user))
      history.push("/campaigns/edit");
  };

  useEffect(() => {
    loadList();

    refreshTimer = setInterval(() => loadList(), 60000);

    return () => clearInterval(refreshTimer);
  }, [phoneNumber]);

  return (
    <>
      <h1>
        Campaigns
        <PhoneNumberDropdown className="mx-10" />
        <Link as="button" to="#" onClick={createOnClick} className="btn btn-plus inline" />
      </h1>

      {loading || (list && list.length)
        ? (
          <div className="card p-10 manage-sms-codes-page">
            <div className="card-toolbar">
              <input type="text" className="form-control search" placeholder="Search Campaigns" onChange={handleSearch} />
              <div className="d-flex fill justify-content-end">
                <Link to="#" onClick={createOnClick} className="btn btn-primary font-weight-bolder font-size-sm mr-3">+ New Campaign</Link>
                <button type="button" className="btn btn-danger font-weight-bolder font-size-sm" disabled={!selected.length} onClick={deleteOnClick}>Delete</button>
              </div>
            </div>

            {loading
              ? (
                <div className="spinner spinner-full"></div>
              )
              : (
                <div className="table-responsive">
                  <table className="table table-head-custom table-vertical-center">
                    <thead>
                      <tr>
                        <th width="10"></th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Message</th>
                        <th>Shows</th>
                        <th>Tags</th>
                        <th>Success</th>
                        <th>Opt-out</th>
                        <th>Delivered</th>
                        <th>Replied</th>
                        <th width="10"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filter(list, search).map((item, i) => (
                        <tr key={i}>
                          <td>
                            <label className="checkbox checkbox-lg checkbox-single">
                              <input type="checkbox" onChange={selectedOnChange} value={item._id} checked={selected.includes(item._id)} />
                              <span />
                            </label>
                          </td>
                          <td>
                            {item.systemDisabled
                              ? <span className="badge gray-badge">Stopped</span>
                              : (
                                <>
                                  {phoneNumber?.kind == "whatsapp" && item.whatsappTemplateStatus == "pending" && <span className="badge yellow-badge">WhatsApp approval pending</span>}

                                  {phoneNumber?.kind == "whatsapp" && item.whatsappTemplateStatus == "rejected" && <span className="badge red-badge">WhatsApp rejected</span>}

                                  {(phoneNumber?.kind != "whatsapp" || item.whatsappTemplateStatus == "approved") && <span className={"badge " + statusColors[item.status]}>{statusLabels[item.status]}</span>}
                                </>
                              )}
                          </td>
                          <td>
                            <Link to={"campaigns/" + item._id + "/edit"} className="text-inherit">
                              {moment(item.sendDate || item.createdAt).locale("en").format("MMM Do, YYYY h:mm A")}
                            </Link>
                          </td>
                          <td>
                            <Link to={"campaigns/" + item._id + "/edit"} className="text-inherit">{item.text}</Link>

                            {phoneNumber?.kind == "whatsapp" && item.whatsappTemplateStatus == "approved" && (
                              <div className="whatsapp-status">
                                <SVG src="/media/def-image/icons/check.svg" />
                                <small>WhatsApp approved</small>
                              </div>
                            )}
                          </td>
                          <td>
                            <div className="products-logos">
                              {item.products.map((product, i) => (
                                <ProductLogo product={product} key={i} />
                              ))}
                            </div>
                          </td>
                          <td>
                            {item.tags.map((tag, i) => (
                              <span key={i} className="badge gray-badge mr-1 my-1">{tag}</span>
                            ))}
                          </td>
                          <td>
                            {item.stats.recipients && item.stats.sent
                              ? (Math.min(100, Math.floor((item.stats.sent / item.stats.recipients) * 100))) + "%"
                              : "0%"}
                          </td>
                          <td>
                            {item.stats.optOut || "0"}
                          </td>
                          <td>
                            {item.stats.delivered || "0"}
                          </td>
                          <td>
                            {item.stats.replies || "0"}
                          </td>
                          <td>
                            <Dropdown className="dropdown table-options-dropdown fixed-dropdown dropdown-inline">
                              <Dropdown.Toggle className={actionLoading == "menu_" + item._id ? " loading spinner spinner-dark" : ""}>
                                <SVG src={toAbsoluteUrl("/media/def-image/icons/menu-2.svg")} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu popperConfig={{ strategy: "fixed" }} renderOnMount>
                                <Dropdown.Item href={"/campaigns/" + item._id + "/edit"}>
                                  <div className="icon">
                                    <SVG src="/media/def-image/icons/edit-2.svg" />
                                  </div>
                                  Edit
                                </Dropdown.Item>
                                {["paused", "pending", "active", "scheduled"].includes(item.status) && (
                                  <Dropdown.Item href="#" onClick={ev => togglePauseItem(ev, item)}>
                                    {item.status == "paused"
                                      ? (
                                        <>
                                          <div className="icon">
                                            <SVG src="/media/def-image/icons/play-2.svg" />
                                          </div>
                                          Resume
                                        </>
                                      )
                                      : (
                                        <>
                                          <div className="icon">
                                            <SVG src="/media/def-image/icons/pause-2.svg" />
                                          </div>
                                          Pause
                                        </>
                                      )}
                                  </Dropdown.Item>
                                )}
                                {phoneNumber.kind == "sms" && (
                                  <Dropdown.Item href={"/campaigns/" + item._id + "/duplicate"}>
                                    <div className="icon">
                                      <SVG src="/media/def-image/icons/duplicate.svg" />
                                    </div>
                                    Duplicate
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item href="#" onClick={ev => deleteItem(ev, item)}>
                                  <div className="icon">
                                    <SVG src="/media/def-image/icons/delete.svg" />
                                  </div>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
          </div>
        )
        : (
          <div className="manage-campaigns-page text-center">
            <SVG src={toAbsoluteUrl("/media/def-image/empty-keywords.svg")} className="mt-20 mb-8 empty-icon" />
            <p className="mb-10 fs-4">Engage with your audience with SMS lists.</p>
            <div className="mb-20">
              <Link to="#" onClick={createOnClick} className="btn btn-primary">Create your first campaign</Link>
            </div>
          </div>
        )}

      <ConfirmModal
        show={showConfirmDelete}
        message={showConfirmDelete === true ? "Are you sure you want to delete the selected campaigns?" : "Are you sure you want to delete the campaign?"}
        onConfirm={confirmDelete}
        onCancel={e => setShowConfirmDelete(false)} />
    </>
  );
}

export default injectIntl(
  connect(
    (state) => ({
      products: state.product.products,
      phoneNumber: state.phoneNumber.current,
      user: state.auth.user,
    }),
    (dispatch) => ({
      ...actions.product,
      ...actions.auth,
      ...actions.phoneNumber,
      dispatch
    })
  )(withRouter(Campaigns))
);
