import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { SubHeaderCustom } from "../../../../_metronic/layout/components/subheader/SubHeaderCustom";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import actions from "../../../../redux/actions";
import api from "../../../../redux/api";
import { NavLink } from "react-router-dom";
import ModalEditCustomer from "./generic/generic/ModalEditCustomer";
import BulkUploadListeners from "./generic/BulkUploadListeners";
import moment from "moment";
import { checkLimits } from "../../../plans";
import ConfirmModal from "../../modals/ConfirmModal";
import SVG from "react-inlinesvg";
import { CSVLink } from "react-csv";
import { Switch, Tooltip } from "@material-ui/core";
import { Dropdown, Modal } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../_metronic/_partials/dropdowns";
import { formatDurationAsText, relativeDate, toastMessage } from "../../../helpers";
import _ from "lodash";

function Progress({ item }) {
  let percent;
  if(!item.episode || !item.episode.publicAudio || !item.episode.publicAudio.durationInSeconds)
    percent = 0;
  else if(!item.status || !item.status.started)
    percent = 0;
  else if(item.status.completed)
    percent = 100;
  else
    percent = Math.round(Math.round(item.status.progress) * 100 / item.episode.publicAudio.durationInSeconds);

  if(isNaN(percent))
    percent = 0;
  else if(percent > 100)
    percent = 100;

  return (
    <div className="progress-container">
      <div className="progress-bar">
        <div className="fill" style={{ width: percent + "%" }} />
      </div>
      <label>{percent + "%"}</label>
    </div>
  );
}

function ListenerActivityModal({ show, onHide, product, listener }) {
  let [loading, setLoading] = useState(true),
    [data, setData] = useState([]);

  let loadData = async () => {
    setLoading(true);

    let res = await api.listener.getActivity(product._id, listener._id);

    if(!res || !res.success)
      return onHide();

    setLoading(false);
    setData(res.data);
  };

  useEffect(() => {
    if(!listener)
      return;

    loadData();
  }, [listener]);

  let remaining = item => {
    if(!item.episode || !item.episode.publicAudio || !item.episode.publicAudio.durationInSeconds)
      return "-";

    let duration = item.episode.publicAudio.durationInSeconds,
      res;

    if(!item.status || !item.status.started)
      res = duration;
    else if(item.status.completed)
      res = 0;
    else
      res = duration - Math.round(item.status.progress);

    if(res > duration)
      res = duration;
    if(res < 0)
      res = 0;

    return formatDurationAsText(res, true) || formatDurationAsText(duration, true);
  },
    status = item => {
      if(item.status && item.status.completed)
        return <span className="green-badge">Completed</span>;

      if(item.status && item.status.started)
        return <span className="blue-badge">In Progress</span>;

      return <span className="gray-badge">Yet to start</span>;
    };

  return (
    <Modal show={show} onHide={onHide} size="xl" centered className="new-modals listener-activity-modal">
      <Modal.Header closeButton>
        <h1 className="m-0">Listener Activity</h1>
      </Modal.Header>
      <Modal.Body>
        {loading
          ? <div className="loading spinner spinner-sm spinner-blue py-20" />
          : (
            <div className="table-responsive">
              <table className="table table-head-custom">
                <thead>
                  <tr>
                    <th className="pl-0">Episode</th>
                    <th className="text-center">Progress</th>
                    <th>Remaining</th>
                    <th>Start date</th>
                    <th>Last listened</th>
                    <th>Times listened</th>
                    <th width="1">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => (
                    <tr key={i}>
                      <td className="pl-0">
                        <strong className="text-muted fs-7">EP {i + 1}</strong><br />
                        {item.episode.title}
                      </td>
                      <td className="text-center">
                        <Progress item={item} />
                      </td>
                      <td>
                        {remaining(item)}
                      </td>
                      <td>
                        {item.status && item.status.started
                          ? moment(item.status.started).locale("en").format("MMMM D, YYYY")
                          : ""}
                      </td>
                      <td>
                        {item.status && item.status.lastListen
                          ? <span className="gray-badge">{relativeDate(item.status.lastListen)}</span>
                          : ""}
                      </td>
                      <td>
                        {item.status && item.status.started ? item.status.timesCompleted : ""}
                      </td>
                      <td>
                        {status(item)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </Modal.Body>
    </Modal>
  );
}

export default injectIntl(connect(
  state => ({
    product: state.product.product || {},
    episodes: (state.product.product || {}).episodes || [],
    listeners: (state.listener || {}).listeners || [],
    user: state.auth.user
  }),
  dispatch => ({
    ...actions.product,
    ...actions.listener,
    dispatch,
  }),
)(ListenerActivityModal));
